import * as React from "react";
import Header from "../components/Header";
import { withPrefix } from "gatsby";
import Footer from "../components/Footer";
import "../scss/style.scss";
import ContactData from "../data/ContactData";
import Banner from "../components/Banner";
import background from "../images/contact.jpg";
import ContactRow from "../components/contact/ContactRow";
import { Helmet } from "react-helmet";
import ContactUs from "../components/buttons/ContactUs";
import ServicesListBasic from "../components/services/ServicesListBasic";

// markup
const ContactPage = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Contact
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <Banner
          title="Contact"
          content="Une question ou envie de prendre rendez-vous ? N'hésitez pas, contactez-nous !"
          background={background}
          position="left"
        />
        <br />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <ul>
                <li>
                  <strong>Téléphones: </strong>
                  <a href="tel:+33952010630" className="text-secondary">
                    09 52 01 06 30
                  </a>
                  <span> - </span>
                  <a href="tel:+33620645033" className="text-secondary">
                    06 20 64 50 33
                  </a>
                </li>
              </ul>
              <h4 className="mt-4">Nos horaires</h4>
              Nous sommes disponible 24h sur 24, du lundi au dimanche,{" "}
              <a href="tel:+33620645033" className="text-secondary">
                sur simple réservation
              </a>
              .
              <div className="row justify-content-center pt-2">
                <ContactUs theme="black" />
              </div>
            </div>
            <div className="col-md-3 offset-md-1 pt-4">
              <h3>Nos prestations :</h3>
              <ServicesListBasic />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default ContactPage;
